.c-header {
  border-bottom: unset;
  min-height: unset;
  margin-bottom: 0;
}

.action-dropdown {
  margin-top: -6px;
}

.action-dropdown::after {
  content: unset;
}

table {
  min-height: 200px;
}

td {
  p {
    margin-bottom: 0;
  }
}

.loading-padding {
  margin: 8px;
}

.select-modal-button:hover {
  background-color: white;
}

.select-modal-button:active {
  background-color: white !important;
}

.full-screen-loading {
  color: white !important;
}

.c-main {
  padding-top: 0;
}

// remove arrow in number input
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.zoom-modal {
  width: 49px;
  height: 49px;
  border-radius: 3px;
  transition: all 0.2s;
}

.finance-main-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));
}

.f-r-dropdown {
  button {
    width: 100%;
    padding: 0;
  }
}

.c-sidebar-nav-item {
  .c-sidebar-nav-link {
    font-weight: 600 !important;
    color: white !important;
  }

  .c-active {
    color: #dedede !important;
  }

  .c-disabled {
    color: #afafaf !important;
    font-weight: 400 !important;
  }
}

.c-sidebar,
.c-sidebar-brand {
  background-color: #3c4b64 !important;
}

.c-sidebar .c-sidebar-nav-link.c-active,
.c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
  background-color: #485368 !important;
}

.c-sidebar-minimizer {
  display: none;
}

.winner-table {
  min-height: 566px !important;
}

.prePagger {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  line-height: 1.25;
  border: 1px solid;
  color: #007aff;
  background-color: #fff;
  border-color: #d8dbe0;
  width: 35px;
  height: 35px;
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  cursor: pointer;

  p {
    margin-top: -1px;
  }
}

.midPagger {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  line-height: 1.25;
  border-top: 1px solid;
  border-bottom: 1px solid;
  background-color: #fff;
  border-color: #d8dbe0;
  min-width: 35px;
  height: 35px;
  margin-left: 0;
}

.nextPagger {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  line-height: 1.25;
  border: 1px solid;
  color: #007aff;
  background-color: #fff;
  border-color: #d8dbe0;
  width: 35px;
  height: 35px;
  margin-left: 0;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  cursor: pointer;

  p {
    margin-top: -1px;
  }
}

.itemPaggerDisable {
  pointer-events: none;
  cursor: auto;
  color: #8a93a2;
  background-color: #fff;
  border-color: #c4c9d0;
}

.pointNumberContainer {
  display: flex;
  flex-direction: row;

  .pointNumber {
    background-color: #007aff;
    color: white;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
  }
}

.sc-textarea {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  color: "#AFAFAF";
  background-color: #e6e6e6;
  padding: 24px;
  border: 1px solid #afafaf;
  border-radius: 5px;
  width: 100%;
  margin-top: 24px;
}

.answer-box {
  display: flex;
  flex-direction: row;

  .answer-item {
    cursor: pointer;
    width: 98px;
    height: 45px;
    background-color: #ebedef;
    color: #707070;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .answer-item:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .answer-item:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .answer-item:hover {
    background-color: #007aff !important;
    color: white !important;
  }
}

.inputBoxContainer {
  display: flex;
  flex-direction: row;

  .input-group-text {
    padding: 0;
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      margin: 0;
    }
  }
}

#media-detail-thumbnail-container {
  width: 100%;
  height: 559px;
  background-color: black;
}

#media-detail-image {
  width: 100%;
  height: 559px;
  object-fit: contain;
}

.media-form-button {
  width: 120px;
}

#preview-text {
  color: #707070;
  font-size: 32px;
}

.media-control-button-text {
  color: #707070;
}

.media-thumbnail {
  object-fit: scale-down;
  width: 100%;
  height: 175px;
}

.media-thumbnail-width-height {
  width: 100%;
  // height: 175px;
  height: 100%;
}

.width-fit-height-fit {
  width: fit-content;
  height: fit-content;
}

.media-header {
  height: 175px;
  object-fit: cover;
  background-color: #a6a6a6;
}

.media-text {
  color: #707070;
  text-transform: uppercase;
  font-size: 12px;
}

.media-text-dimension-or-length {
  color: #707070;
  text-transform: lowercase;
  font-size: 12px;
}

.media-menu-icon {
  top: 5px;
  right: 5px;
}

.media-title {
  font-size: 14px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.media-detail-form-button-container {
  position: absolute;
  bottom: 0;
  right: 1rem;
}

.form-control[readonly].media-detail-form-special-readonly-input {
  background-color: #fff;
  opacity: 1;
}

#media-thumbnail-video {
  max-width: 100%;
  max-height: 558px;
}

#media-detail-delete-modal-header-text {
  font-size: 24px;
  color: #007aff;
  text-align: left;
}

#media-detail-loading-modal-body {
  font-size: 24px;
  color: #707070;
  width: fit-content;
  margin: auto;
}

#media-detail-modal-button-delete {
  font-size: 20px;
}

.media-top-button {
  color: #fff;
  font-size: 20px;
  text-decoration: none;
  padding-left: 20px;
  padding-right: 20px;

  svg {
    margin-right: 25px;
    position: relative;
    top: -2px;
  }
}

.media-modal-header {
  color: $media-color-primary;
  font-size: 20px;
  text-decoration: none;

  svg {
    margin-right: 25px;
    position: relative;
    top: 2px;
  }
}

.drop-item-color {
  color: #006fff;

  p {
    margin: unset;
    padding-left: 10px;
  }
}

.label-color {
  color: "#707070";
  font-size: "20px";
}

.drop-down-toggle {
  border: 1px solid #d8dbe0;
  padding: 6px;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #768192;
}

.title-head {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}

#media-detail-breadcrumb-container {
  width: 100%;
  margin-left: -15px;
}

#media-detail-container {
  padding-left: 20px;
  padding-right: 20px;
}

.modal-content#media-detail-loading-modal {
  background-color: transparent !important;
  border: 1px solid transparent !important;
}

/************
 ** DropZone *
 ************/
.dropzone,
.dropzone * {
  box-sizing: border-box;
}

.dropzone {
  color: #999;
  font-size: 18px;
  min-height: 150px;
  border: 2px dashed rgba(0, 0, 0, 0.3);
  background: #ddd;
  padding: 54px 54px;
  text-align: center;
}

.dropzone.dz-clickable {
  cursor: pointer;
}

.dropzone.dz-clickable * {
  cursor: default;
}

.dropzone.dz-clickable .dz-message,
.dropzone.dz-clickable .dz-message * {
  cursor: pointer;
}

.dropzone.dz-started .dz-message {
  display: none;
}

.dropzone.dz-drag-hover {
  border-style: solid;
}

.dropzone.dz-drag-hover .dz-message {
  opacity: 0.5;
}

.dropzone .dz-message {
  text-align: center;
  margin: 2em 0;
}

.dropzone .dz-preview {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 16px;
  min-height: 100px;
}

.dropzone .dz-preview:hover {
  z-index: 1000;
}

.dropzone .dz-preview:hover .dz-details {
  opacity: 1;
}

.dropzone .dz-preview.dz-file-preview .dz-image {
  border-radius: 20px;
  background: #999;
  background: linear-gradient(to bottom, #eee, #ddd);
}

.dropzone .dz-preview.dz-file-preview .dz-details {
  opacity: 1;
}

.dropzone .dz-preview.dz-image-preview {
  background: white;
}

.dropzone .dz-preview.dz-image-preview .dz-details {
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -ms-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
}

.dropzone .dz-preview .dz-remove {
  font-size: 14px;
  text-align: center;
  display: block;
  cursor: pointer;
  border: none;
}

.dropzone .dz-preview .dz-remove:hover {
  text-decoration: underline;
}

.dropzone .dz-preview:hover .dz-details {
  opacity: 1;
}

.dropzone .dz-preview .dz-details {
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  font-size: 13px;
  min-width: 100%;
  max-width: 100%;
  padding: 2em 1em;
  text-align: center;
  color: rgba(0, 0, 0, 0.9);
  line-height: 150%;
}

.dropzone .dz-preview .dz-details .dz-size {
  margin-bottom: 1em;
  font-size: 16px;
}

.dropzone .dz-preview .dz-details .dz-filename {
  white-space: nowrap;
}

.dropzone .dz-preview .dz-details .dz-filename:hover span {
  border: 1px solid rgba(200, 200, 200, 0.8);
  background-color: rgba(255, 255, 255, 0.8);
}

.dropzone .dz-preview .dz-details .dz-filename:not(:hover) {
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropzone .dz-preview .dz-details .dz-filename:not(:hover) span {
  border: 1px solid transparent;
}

.dropzone .dz-preview .dz-details .dz-filename span,
.dropzone .dz-preview .dz-details .dz-size span {
  background-color: rgba(255, 255, 255, 0.4);
  padding: 0 0.4em;
  border-radius: 3px;
}

.dropzone .dz-preview:hover .dz-image img {
  -webkit-transform: scale(1.05, 1.05);
  -moz-transform: scale(1.05, 1.05);
  -ms-transform: scale(1.05, 1.05);
  -o-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
  -webkit-filter: blur(8px);
  filter: blur(8px);
}

.dropzone .dz-preview .dz-image {
  border-radius: 20px;
  overflow: hidden;
  width: 120px;
  height: 120px;
  position: relative;
  display: block;
  z-index: 10;
}

.dropzone .dz-preview .dz-image img {
  display: block;
}

.dropzone .dz-preview.dz-success .dz-success-mark {
  -webkit-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  -ms-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  -o-animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
  animation: passing-through 3s cubic-bezier(0.77, 0, 0.175, 1);
}

.dropzone .dz-preview.dz-error .dz-error-mark {
  opacity: 1;
  -webkit-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -moz-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -ms-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  -o-animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
  animation: slide-in 3s cubic-bezier(0.77, 0, 0.175, 1);
}

.dropzone .dz-preview .dz-success-mark,
.dropzone .dz-preview .dz-error-mark {
  pointer-events: none;
  opacity: 0;
  z-index: 500;
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  margin-left: -27px;
  margin-top: -27px;
}

.dropzone .dz-preview .dz-success-mark svg,
.dropzone .dz-preview .dz-error-mark svg {
  display: block;
  width: 54px;
  height: 54px;
}

.dropzone .dz-preview.dz-processing .dz-progress {
  opacity: 1;
  -webkit-transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  -ms-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.dropzone .dz-preview.dz-complete .dz-progress {
  opacity: 0;
  -webkit-transition: opacity 0.4s ease-in;
  -moz-transition: opacity 0.4s ease-in;
  -ms-transition: opacity 0.4s ease-in;
  -o-transition: opacity 0.4s ease-in;
  transition: opacity 0.4s ease-in;
}

.dropzone .dz-preview:not(.dz-processing) .dz-progress {
  -webkit-animation: pulse 6s ease infinite;
  -moz-animation: pulse 6s ease infinite;
  -ms-animation: pulse 6s ease infinite;
  -o-animation: pulse 6s ease infinite;
  animation: pulse 6s ease infinite;
}

.dropzone .dz-preview .dz-progress {
  opacity: 1;
  z-index: 1000;
  pointer-events: none;
  position: absolute;
  height: 16px;
  left: 50%;
  top: 50%;
  margin-top: -8px;
  width: 80px;
  margin-left: -40px;
  background: rgba(255, 255, 255, 0.9);
  transform: scale(1);
  border-radius: 8px;
  overflow: hidden;
}

.dropzone .dz-preview .dz-progress .dz-upload {
  background: #333;
  background: linear-gradient(to bottom, #666, #444);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
  -webkit-transition: width 300ms ease-in-out;
  -moz-transition: width 300ms ease-in-out;
  -ms-transition: width 300ms ease-in-out;
  -o-transition: width 300ms ease-in-out;
  transition: width 300ms ease-in-out;
}

.dropzone .dz-preview.dz-error .dz-error-message {
  display: block;
}

.dropzone .dz-preview.dz-error:hover .dz-error-message {
  opacity: 1;
  pointer-events: auto;
}

.dropzone .dz-preview .dz-error-message {
  pointer-events: none;
  z-index: 1000;
  position: absolute;
  display: block;
  display: none;
  opacity: 0;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
  border-radius: 8px;
  font-size: 13px;
  top: 130px;
  left: -10px;
  width: 140px;
  background: #be2626;
  background: linear-gradient(to bottom, #be2626, #a92222);
  padding: 0.5em 1.2em;
  color: white;
}

.dropzone .dz-preview .dz-error-message:after {
  content: "";
  position: absolute;
  top: -6px;
  left: 64px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #be2626;
}

/** End DropZone */

#media-thumbnail-container:hover {
  cursor: pointer;
}

// media library
.media-library-section-title,
#notification-section-title,
.store-section-title {
  color: #707070;
  font-size: 20px;
}

// end media library

// remove shadow from button when clicked
.btn:focus {
  box-shadow: none;
}

.btn-outline-info.button-copy-url {
  color: #707070;
  border-color: #d8dbe0;
}

.btn-outline-info.button-copy-url:hover {
  color: #ffffff;

  .icon-copy-url {
    color: #ffffff;
  }
}

.three-dot-button-background {
  background-color: #00000030;
}

.toast-default-success {
  .toast-body {
    color: $media-color-success;
  }
}

/* service card section */
.service-card-box {
  height: auto;
  background-color: #ffffff;
  border-radius: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: 0px 3px 6px #00000029;
  color: #707070;
  margin: 10px 12px;
  cursor: pointer;
}

.service-card-box:hover {
  background-color: #a6caff;

  .service-card-text {
    color: #000000;
  }
}

.service-card-image-container {
  width: 100px;
  height: 100px;
  border: 1px solid transparent;
  margin-top: 15px;
}

.service-card-image {
  border-radius: 10px;
}

.service-card-text {
  font-size: 18px;
}

.service-card-media-count-container,
.app-card-notification-subscriber-count-container {
  width: calc(100% - 10px);
  margin-top: 5px;
}

.service-card-media-icon {
  color: #006fff;
}

/* end service card section */

.wrap-search-input {
  align-items: center;
  background: white;
  padding-left: 10px;
}

.search-input {
  width: 90%;
  border: unset;
  border-radius: 0;
  background: unset;
  color: #768192;
  outline: none;
}

.user-group {
  padding: 0.75rem 2.25rem;
  margin-bottom: 0px;
  border-radius: 6px;
  background-color: #fff;
  border-color: #d8dbe0;
  box-shadow: 0px 0px 5px #00000029;
  margin-top: 10px;
  cursor: pointer;
}

.user-group-item {
  opacity: 1px;
  display: flex;
  justify-content: space-between;
}

.text-style {
  div {
    margin-bottom: 15px;
  }

  p {
    padding: 3px 0px;
    margin: 0px;
  }

  img {
    margin-top: 10px;
  }
}

.form-check-input {
  margin-left: 0px !important;
}

.react-datepicker__input-container input {
  border: 1px solid #afafaf;
  border-radius: 5px;
  color: #707070;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 15px;
}

#user-group-list-table-container table,
#subscriber-list-table-container table {
  margin-bottom: 0px;
}

.app-card-subscriber-icon {
  color: #006fff;
}

.app-card-subscriber-number {
  color: #707070;
}

.subscriber-list-button-edit:hover {
  cursor: pointer;
  // Head tap
}
.text-muted {
  color: red !important;
}

.nav-tabs {
  border-bottom: none;

  .nav-item {
    margin-right: 5px;
  }

  .nav-link {
    width: 120px;
    text-align: center;
    background-color: #afafaf;
    color: #ffffff;
    border-bottom-color: #007aff;
  }

  .nav-link.active {
    color: #007aff;
    background-color: #ffffff;
    border-color: #007aff #007aff transparent;
  }

  .nav-item.show {
    .nav-link {
      color: #000;
      border-color: #007aff #007aff #007aff;
    }
  }
}

// body tap
.user-table {
  margin-top: 15px;

  thead {
    th {
      vertical-align: bottom;
      border-bottom: unset;
      border-bottom-color: unset;
      color: #707070;
    }
  }

  tbody {
    tr {
      cursor: pointer;

      &:nth-child(even) {
        background: #ffffff;
      }

      &:nth-child(odd) {
        background: #e9f3ff;
      }
    }
  }

  th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: unset;
    border-top-color: unset;
  }

  td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: unset;
    border-top-color: unset;
  }
}

.table-hover {
  tbody {
    tr {
      &:hover {
        color: #3c4b64;
        background-color: #00000029;
      }
    }
  }
}

.panel-tabs {
  background-color: #ffffff;
  border: 1px solid #007aff;
  border-top: unset;
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.dot {
  height: 10px;
  width: 10px;
  background-color: #007aff;
  border-radius: 50%;
  display: inline-block;
}

.form-control:disabled.text-black,
.form-control[readonly].notification-update-user-tags-list-tag-input {
  background-color: #fff;
  opacity: 1;
}

.breadcrumb {
  padding-left: 0 !important;
}

.form-group.no-margin-bottom {
  margin-bottom: 0px !important;
}

label.no-margin-bottom {
  margin-bottom: 0px !important;
}

.icon-group {
  margin-right: 10px;
}

.form-group {
  margin-bottom: 15px;

  .form-control {
    display: block;
    width: 100%;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

  input.form-control {
    height: 34px;
  }

  label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
  }
}

.input-group-header {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;

  p,
  label,
  span {
    margin: 0;
  }
}

.radio-inline {
  float: left;
  margin-right: 20px;

  input[type="radio"] {
    margin-right: 10px;
  }

  input[type="checkbox"] {
    margin-right: 10px;
  }
}

.cat-tree-item {
  position: relative;
  font-size: 2em;
  vertical-align: middle;
  padding-right: 70px;
}

.cat-tree-item span {
  font-size: 20px;
}

.hidden-container {
  display: none;
}

.icon-trash-group {
  position: absolute;
  cursor: pointer;
  right: 10px;
  color: #ced2d8;
}

.icon-edit-group {
  position: absolute;
  cursor: pointer;
  right: 30px;
  color: #ced2d8;
}

.icon-edit-group:hover {
  color: #f9b115;
}

.icon-trash-group:hover {
  color: #f27474;
}

.ch-group {
  margin-left: 15px;
  position: relative;
  top: 2px;
}

.cat-tree-item:hover > .hidden-container {
  display: block;
}

.clearfix {
  overflow: auto;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

// table.payment-option tr {
//   // border-top: 1px solid #ccc;
// }

table.payment-option tr:first-child {
  border-top: none;
}

table.payment-option tr td,
table.video-flavor tr td {
  padding: 10px 0;
}

table.payment-option tr td p {
  color: #999;
}

table.video-flavor tr td p {
  color: #666;
}

table.payment-option tr td:first-child {
  width: 30%;
}

table.payment-option tr td:first-child p {
  color: #333;
}

.text-center {
  text-align: center;
}

.is-invalid.create-template-field {
  border: 1px solid red;
}

.add-product-template-icon-delete-custom-field:hover {
  cursor: pointer;
  color: #ff4136;
}

.icon-delete-custom-field:hover {
  color: #ff4136;
  cursor: pointer;
}

.icon-edit-custom-field:hover {
  color: #f9b115;
  cursor: pointer;
}

.edit-product-template-default-field-checkbox:hover {
  cursor: pointer;
}

.row.product-template-form-static-fields-wraper {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

span.cat-tree-item span {
  text-transform: initial !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-weight: normal !important;
  font-size: initial !important;
}

.element-value-item {
  background-color: white;
  border: 1px solid #ced2d8;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5.5px;
  padding-bottom: 5.5px;
  border-radius: 3px;
}

.row.crow-no-mr-ml {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.btn-remove-element-value:hover {
  color: #ff4136;
  background-color: white;
  border-color: #ff4136;
}

.btn-delete:hover {
  color: #ff4136;
  background-color: white;
  border-color: #ff4136;
}

.btn-add:hover {
  color: #007aff;
  background-color: white;
  border-color: #007aff;
}

button.w-100.soyonow-category-dropdown-toggle.btn {
  padding: 0px;
}

.category-item,
.tag-item,
.actor-item {
  height: 100%;
  border: 1px solid gray;
  border-radius: 7px;
  padding-left: 4px;
  padding-right: 4px;
}

.category-input-item {
  margin-top: 2.5px;
  height: fit-content;
  border-width: 0px;
  padding-left: 4px;
  background-color: #e6e6e6;
  border-radius: 2px;
}

.col.no-padding-x {
  padding-left: 0px;
  padding-right: 0px;
  margin-left: 1px;
  margin-top: 1px;
}

.icon-remove-category:hover,
.icon-remove-tag:hover,
.icon-remove-actor:hover {
  color: #ff4136;
}

.thumbnails-container {
  background-color: #dadada;
  padding: 5px;
}

.btn-remove-thumbnail {
  border-width: 0px;
  padding-right: 2px;
}

.icon-remove-thumbnail {
  padding-left: 0px !important;
}

.icon-remove-thumbnail:hover {
  cursor: pointer;
  color: #ff4136;
}

#btn-remove-video:hover {
  cursor: pointer;
  color: #ff4136;
}

label.ant-transfer-list-checkbox {
  display: inherit;
}

div.ant-transfer-list {
  width: 50%;
  height: 300px;
}

.datepicker-full-width .react-datepicker-wrapper {
  width: 100%;
}

.tbl-video-preview tr td {
  line-height: 1.5;
  padding: 8px 0;
}

// custom sub nav icon
.c-sidebar-sub-nav-icon {
  color: #ced2d8;
  width: 1rem;
  height: 1rem;
  font-size: 1rem;
  margin-right: 10px;
}

.remove-category-icon {
  height: 24px;
  margin-left: 5px;
  padding-left: 2px;
  padding-right: 2px;
  width: 22px;
  border-radius: 2px;
}

.remove-category-icon:hover {
  background-color: #ffbdad;
  color: #de350b;
}

.category-name-display {
  font-size: 9pt;
}

.subtitles-container {
  border-radius: 5px;
  border: 1px solid #cccccc;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 5px;
}

.subtitle-item {
  border-radius: 0px;
  border: 1px solid #e2e2e2;
  padding: 3px;
  margin-top: 5px;
  background-color: #efefef;
}

.remove-subtitle-icon:hover {
  color: #ff4136;
  cursor: pointer;
}

#subtitle-input {
  display: none;
}

.transaction-history {
  .nav-tabs {
    border-color: rgba(0, 0, 0, 0);
  }

  .nav-tabs .nav-link {
    border-bottom-color: #afafaf;
  }

  .nav-tabs .nav-link.active {
    border-color: #ffffff #ffffff transparent;
    font-weight: bold;
  }
}

.commission-pop-up {
  .nav {
    padding-left: 16px !important;
  }

  .nav-tabs {
    border-color: rgba(0, 0, 0, 0);
  }

  .nav-tabs .nav-link {
    border-bottom-color: #afafaf;
  }

  .nav-tabs .nav-link.active {
    border-color: #afafaf #afafaf transparent;
  }
}

.sidebar-padding {
  padding-top: 15px;
}

//SummaryComponents
.summary-chart:hover {
  color: #40a9ff;
}

.summary-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #d8d8d8;
}

.text-summary-color {
  color: #707070;
  font-weight: 600;
  font-size: 1rem;
}

// Align Text in table center
.table td {
  vertical-align: text-top;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  // .summary-modal {
  //   max-height: 1200;
  // }
  .modal-xl {
    max-width: 456px;
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .modal-xl {
    max-width: 648px;
  }

  // .summary-modal {
  //   max-height: 900px;
  // }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .modal-xl {
    max-width: 872px;
  }

  // .summary-modal {
  //   max-height: 900px;
  // }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1080px;
  }

  // .summary-modal {
  //   max-height: 900px;
  // }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
  .modal-xl {
    max-width: 1280px;
  }

  // .summary-modal {
  //   max-height: 900px;
  // }
}

@media (min-width: 1600px) {
  .modal-xl {
    max-width: 1500px;
  }
}

@media (max-height: 800px) {
  .modal-xl {
    max-height: 600px;
  }

  // .summary-modal {
  //   max-height: 600px;
  // }
  .summary-graph {
    height: 200px;
  }
}

@media (min-height: 800px) {
  .modal-xl {
    max-height: 900px;
  }

  // .summary-modal {
  //   max-height: 600px;
  // }
  .summary-graph {
    height: 500px;
  }
}

// draft editor
.rdw-editor-main {
  border: 1px solid rgb(220, 220, 220);
  border-radius: 4px;
  padding: 0 10px 0 10px;
}

.DraftEditor-root {
  height: 300px !important;
}

#mysabay-user-profile-image-container {
  width: 115px;
  height: 115px;
}

.mysabay-user-profile-info-text {
  font-size: 16px;
  color: rgb(151, 151, 151);
}

.mysabay-user-profile-heading {
  font-size: 16px;
  margin-bottom: 10px;
}

.mysabay-user-profile-body {
  font-size: 14px;
  color: rgb(116, 116, 116);
}

.mysabay-user-information-box {
  border: 1px solid rgb(228, 228, 228);
  padding: 10px;
  border-radius: 5px;
}

.mysabay-sidebar #add-new-jx2 {
  padding-left: 4.3rem;
}

.divider {
  border-bottom: 1px solid #CED2D8;
  width: 100%;
  margin: 16px;
}

.row {
  display: flex;
  flex-direction: row;
}

.center-vertical {
  display: flex;
  align-items: center;
}

.modal-title {
  color: #007aff;
  font-weight: 700;
  font-size: 18px;
}